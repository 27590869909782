.containerContact {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  margin: 20px 10px 0;

  .contentPinguino {
    width: 40%;
    margin-right: 20px;
    min-width: 420px;

    .imgVolume {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 50%;
      margin-bottom: 30px;
    
      img {
        width: 20%;
        margin-right: 15px;
      }
      h1 {
        font-size: 2.8em;
      }
    }

    .pSubTitle {
      font-style: italic;
      margin-left: 20px;
      margin-bottom: 2px;
    }

    .italic {
      font-style: italic;
      margin-top: 10px;
    }
  }
}


@media (min-width: 768px) and (max-width: 1023px){
  .containerContact {
    flex-direction: column;

    .contentPinguino {
      width: 80%;
      min-width: initial;
      margin: 0 0 30px;
    }
  }
}

.containerBlockDescription {
  display: flex;
  justify-content: space-around;
  padding: 10px 0 55px;
}

@media (max-width: 767px){
  .containerContact {
    flex-direction: column;
    margin: 0;

    .contentPinguino {
      width: 80%;
      min-width: initial;
      margin: 0 0 30px;

      .imgVolume {
        img {
          width: 20%;
          margin-right: 15px;
        }
        h1 {
          font-size: 1.8em;
        }
      }

    }
  }

  .containerBlockDescription {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

